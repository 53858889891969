<template>
  <div>
    <h3>26 March 2010 (0.181.5)</h3>
    <p>
      <u>Gnarled Forest Shooting Gallery</u>
      <br>- The Shooting Gallery should load and be playable regardless of player input during the loading time.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Swifty McGurk should have the correct texture.
    </p>
    <p>
      <u>UI</u>
      <br>- The text in the “Bug Reporting” field has been updated. “For Help, please email techsupport_uk@legouniverse.com , to Report Bugs, please email bugreport_uk@legouniverse.com and for Player Reporting, please email safetyreport@lego.com .”
    </p>
    <h3>25 March 2010 (0.181.4)</h3>
    <p>
      <u>Launchpads</u>
      <br>- If a user attempts to use a launchpad that is blocked, then they will see a message saying “This launchpad is not available in Beta.”
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- The Survival mini-game is closed off.
      <br>- If the user attempts to open the Survival instance, they will only see the message “Survival Mini-Game is not available in Beta.”
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The Gnarled Forest Launchpad hologram will now display over the ocean correctly.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Swifty McGurk has his correct textures, and is no longer pink.
    </p>
    <p>
      <u>Build Mode</u>
      <br>- Attempting to equip a helmet in Modular Build will unequip your thinking hat and take you out of Modular Build Mode. It will NOT equip your helmet.
    </p>
    <p>
      <u>Chat</u>
      <br>- Hitting “enter” will no longer create a new line before sending the line to Chat moderation.
      <br>- The “Tab” button has also been disabled.
    </p>
    <p>
      <u>Showcase</u>
      <br>- The blue boxes that define free build areas throughout the world have been removed.
    </p>
    <h3>22 March 2010 (0.181.3)</h3>
    <p>
      <u>Spaceship</u>
      <br>- The camera should no longer get stuck on Bob, and the new mission window will open as it should.
    </p>
    <p>
      <u>Factions</u>
      <br>- Faction badges will be removed from inventory correctly after turning in the quest “Who are you?”
    </p>
    <p>
      <u>Brick Mode</u>
      <br>- Reward models cannot be picked up while in a Free Build area in the world.
    </p>
    <h3>18 March 2010 (0.181.2)</h3>
    <p>
      <u>Gnarled Forest Shooting Gallery</u>
      <br>- Now unlocked Spaceship
      <br>- The camera should no longer get stuck on Bob, and the new mission window will open as it should.
    </p>
    <p>
      <u>Blocked Content</u>
      <br>- All of the mini-games currently blocked to Closed-Beta testers, such as AG Survival, will now display the less confusing message “ is unavailable in Beta at this time.”
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The Nexus Force Recruiter mission chain should now immediately prompt the player to interact with a faction rep to obtain their Specialty Kit after turning in all Faction Badges.
    </p>
    <h3>17 March 2010 (0.181.1)</h3>
    <p>
      <u>Gnarled Forest</u>
      <br>- Characters will not be blown back by the Parrot Ninja Detector once they are out of range.
    </p>
    <p>
      <u>Gnarled Forest Shooting Gallery</u>
      <br>- You will no longer spawn and become stuck in the shooting gallery if they try jumping while the game is loading.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The launchpad from Nimbus Station to Gnarled Forest is now usable by all Closed-Beta testers.
      <br>- You should no longer become stuck after transitioning to Nimbus Station from other areas.
      <br>- You should not become stuck during framerate slowdown.
    </p>
    <p>
      <u>Duck Build</u>
      <br>- The Duck Build mission is completed automatically and Thinking Hat rewarded upon talking to the Duck Build mission giver.
      <br>- Note: You must have Mardolf’s mission as a prerequisite.
    </p>
    <p>
      <u>Brick Mode</u>
      <br>- You can only drag and/or load models with the “Select Tool”.
    </p>
    <h3>17 March 2010 (0.181.0)</h3>
    <p>
      <u>Imagination Brick</u>
      <br>- The “Gold Brick” is now the “Blue Imagination Brick”.
    </p>
    <p>
      <u>Character Create</u>
      <br>- You can no longer try to delete or play a character you are renaming.
    </p>
    <p>
      <u>Avant Gardens Property</u>
      <br>- The Avant Gardens Starter property has changed!
      <br>- Rocket launchpads on property maps will now have an aura effect if the property is not moderated or not public access.
    </p>
    <p>
      <u>Minimap</u>
      <br>- The Minimap appearance has been updated.
      <br>- The Minimap will now show the locations of Mission Givers, Vendors, and Rocketpads.
      <br>- Map UI icon is now functional.
    </p>
    <p>
      <u>Reward Objects</u>
      <br>- If you try to accept a reward item and your bags are full, then you will now see a message saying “All your bags are full!”
      <br>- Previously, there was no message indicating why you couldn’t pick up a mission reward.
      <br>- If you try to delete an undeletable item, then you will now see a message describing why you cannot delete that item.
    </p>
    <p>
      <u>Achievements</u>
      <br>- Achievements have undergone a re-balancing.
      <br>- Some of the consumable rewards have been replaced with bricks, and in other cases the number of times you have to complete the achievement task to get the achievement has been increased.
      <br>- This is in response to feedback that the player is getting too many items too quickly at the start of the game, without knowing the value of them, and consequently, having a full backpack.
      <br>- Some specific changes:
      <br>- Increased the first Avant Guardian 1 from 25 smashes to 50 smashes
      <br>- Changed the Radio Free LEGO mission reward from consumables to bricks
      <br>- Changed the Finish Line mission reward from consumables to bricks
      <br>- Changed Brick Collection reward from consumables to bricks
      <br>- Changed the Avant Gardens Achiever 1 achievement from 10 to 20
      <br>- Changed the Paradox Protector 1 achievement from 5 to 25 smashes
      <br>- Changed the Defeat the Maelstrom 1 achievement from 5 to 10 smashes
      <br>- Turned down the drop rate slightly on loot from AG mobs
    </p>
    <p>
      <u>Modular Build</u>
      <br>- When you approach a dropped module, the interaction icon and the rings appear.
      <br>- Player characters are no longer able to get stuck outside of the modular build bounds while in build mode.
    </p>
    <p>
      <u>UI</u>
      <br>- Rebuild window is removed when selecting Switch Minifig or Logout while your character is “smashed.”
    </p>
    <p>
      <u>Model Mode</u>
      <br>- “Property Edit Mode” is now called “Model Mode”.
      <br>- Even- and odd-studded models now align properly!
    </p>
    <p>
      <u>Brick Mode</u>
      <br>- “Free Build” is now called “Brick Mode”.
      <br>- You may now place a model on your property immediately after saving the model in Brick Mode, even if it has not yet been optimized.
      <br>- Brick Mode will no longer clear the selection if you click on something that's not a brick while holding Ctrl or Shift.
      <br>- This should make accidental de-selections less common.
      <br>- Switching Minifig or Logging out in Model Mode or Brick Mode no longer causes 'My Stuff' tab in inventory to become inaccessible upon returning.
    </p>
    <p>
      <u>Frame Rates</u>
      <br>- Better input at low frame rates (including Jumping)
      <br>- Input for character, vehicle, and any other control scheme in the game will now be handled properly at any frame rate.
      <br>- Previously, if the frame rate dropped low enough, button presses could be missed entirely, which made anything that didn’t require holding down a button almost impossible.
      <br>- This should make the min-spec controls experience quite a bit easier.
    </p>
    <p>
      <u>Factions</u>
      <br>- Once you’ve joined a faction, you will now (randomly) receive faction tokens by doing things in the game (smashing things, quickbuilds, playing activities, etc.)
      <br>- The icons currently on these items are placeholder.
      <br>- You will receive tokens only for your faction. You use these to purchase new and higher ranks of specialty kit items.
    </p>
    <p>
      <u>Chat</u>
      <br>- Updated the chat box to properly "red-out" words that have capital letters in them
      <br>- The enter key properly functions on the chat box now.
    </p>
    <p>
      <u>Passport</u>
      <br>- Passport tab for Pet Cove now says “Pet Cove”, not “Pet Rock”.
    </p>
    <p>
      <u>Vendors</u>
      <br>- Vendor currency displays now accommodate large values.
    </p>
    <p>
      <u>NPC's</u>
      <br>- If you are interacting with an NPC, object, or other thing, then you cannot interact with other testers, objects, or NPC’s until your current interaction is complete.
    </p>
    <p>
      <u>Gnarled Forest Shooting Gallery</u>
      <br>- You are now frozen in place and fades to invisible after accepting the minigame.
      <br>- You can exit the GF Shooting Gallery at any time.
      <br>- You no longer lose streak when missing a shot while in Supercharge mode.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The tiki torch can now be extinguished with the water gun.
    </p>
    <h3>15 March 2010 (0.180.10)</h3>
    <p>
      <u>Min Spec Messages</u>
      <br>- If a your computer is below min spec, then one of the following messages will be displayed:
      <br>1. LEGO Universe cannot be launched. Unsupported processor.
      <br>2. LEGO Universe cannot be launched. Unsupported video card.
    </p>
    <h3>12 March 2010 (0.180.8)</h3>
    <p>
      <u>Character Create</u>
      <br>- The “Rename” dialog will no longer become stuck.
    </p>
    <p>
      <u>Chat</u>
      <br>- The chat box will now properly “red-out” words that have capital letters in them.
    </p>
    <p>
      <u>Miscellaneous</u>
      <br>- If you equip an item, then there will not be as much hitching.
    </p>
    <h3>11 March 2010 (0.180.7)</h3>
    <p>
      <u>Nimbus Station</u>
      <br>- Characters should no longer be getting stuck in the trees near the Forbidden Valley launchpad.
      <br>- During framerate slow downs, characters should no longer get stuck.
      <br>- The Choice Builds in the Concert scene now work correctly.
    </p>
    <p>
      <u>Character Create</u>
      <br>- When renaming a moderating character, the play and delete buttons are now disabled.
      <br>- Pressing the back button will cause the rename dialog to close itself properly.
    </p>
    <p>
      <u>Free Build</u>
      <br>- Players will no longer get their bricks back when saving models. Models can also be edited after being saved.
    </p>
    <p>
      <u>Chat</u>
      <br>- The predictive whitelist has been updated to include LU game terms.
    </p>
    <p>
      <u>Quickbuilds</u>
      <br>- Quickbuilds should no longer lose functionality during the progression state.
    </p>
    <h3>9 March 2010 (0.180.6)</h3>
    <p>
      <u>Launchpads</u>
      <br>- Attempting to use a blocked launch pad will now display a message saying “This launch pad is not yet open.”
      <br>- When you place your rocket on the Avant Gardens Property launchpad, the Property Browser UI will appear.
    </p>
    <p>
      <u>Character Create</u>
      <br>- If you have to rename their minifig after their name has been rejected by moderation, the “Rename Character” UI should now be readable.
    </p>
    <p>
      <u>Property Edit</u>
      <br>- Picking up and placing models in Property Edit will no longer cause the player to become stuck.
    </p>
    <h3>8 March 2010 (0.180.4)</h3>
    <p>
      <u>Chat</u>
      <br>- The chat window will now automatically scroll to the bottom when new text is typed.
    </p>
    <p>
      <u>Cinematics</u>
      <br>- After interacting with an NPC or object that plays a cinematic, you are “stunned” and cannot interact with another target until the cinematic is complete.
    </p>
    <p>
      <u>Log-In</u>
      <br>- After a failed game log-in, the links for “Create LEGO ID” and “Forgot your password?” will now open the correct webpages.
    </p>
    <h3>4 March 2010 (0.180.3)</h3>
    <p>
      <u>Character Create</u>
      <br>- The “Rename Character” UI no longer has black-on-black text. The text and background are now in contrasting colors.
      <br>- Deleting a character is now permanent.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Quickbuilds will now work for multiple players if the latency rate is high.
    </p>
    <p>
      <u>Inventory</u>
      <br>- Brick inventory Group Names and data will save automatically.
    </p>
    <p>
      <u>Missions</u>
      <br>- Epsilon’s “Armor Polish” mission will no longer cause the camera to become stuck.
    </p>
    <p>
      <u>Friends and Ignore List</u>
      <br>- Opening and closing the Friend Menu will no longer cause a “dead zone” where the mouse cannot click.
      <br>- You can no longer add yourself to your own Friend and Ignore lists.
    </p>
    <p>
      <u>Modular Build</u>
      <br>- Users can no longer exit the rocket ship modular build in either the Spaceship or Avant Gardens.
    </p>
    <h3>2 March 2010 (0.180.2)</h3>
    <p>
      <u>Free Build</u>
      <br>- You will no longer become stuck from placing and picking up models.
    </p>
    <p>
      <u>Epsilon Mission Flow is now:</u>
      <br>- Destroy 10 enemies
      <br>- Talk to Beck
      <br>- Find the Spider Cave Entrance
      <br>- Bring Epsilon Armor Polish
    </p>
  </div>
</template>
